import { render, staticRenderFns } from "./apoverlag-logo-nav-downloads.svg?vue&type=template&id=09b8490f"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports