<template>
  <div class="page">
    <PageHeader />
    <main>
      <Nuxt />
    </main>
    <StickyNote />
    <PageFooter />
  </div>
</template>

<script>
import PageHeader from '@/components/header/default'
import StickyNote from '@/components/sticky-note/default'
import PageFooter from '@/components/footer/default'

export default {
  components: {
    PageHeader,
    PageFooter,
    StickyNote,
  },
}
</script>

<style lang="scss">
@import '../css/import/vars';

.page {
  max-width: $page-max-width;
  min-width: $page-min-width;
  margin: 0 auto;
  width: 100%;

  main {
    @media print {
      margin-top: 0;
    }
  }
}
</style>
