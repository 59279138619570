/**
 * Browser-related polyfills should be enabled here, ECMA-script features should
 * directly be configured in the nuxt.config.js via babel/core-js
 */

import 'intersection-observer'
import smoothscroll from 'smoothscroll-polyfill'
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'

smoothscroll.polyfill()

window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill
