<template>
  <div class="newsletter">
    <div class="newsletter__text-wrapper">
      <h2>Newsletter</h2>
      <p>Informative Neuigkeiten und Aktionen aus dem APOVERLAG.</p>
    </div>
    <div class="newsletter__buttons">
      <ButtonExternalLink link="http://sys.mailworx.info/Form/Index?frm=8caebb51-5f9e-4523-a832-dfecce13f08a">
        Abonnieren
      </ButtonExternalLink>
      <ButtonLink :link="{ path: '/apoverlags-newsletterarchiv' }"> Newsletter Archiv </ButtonLink>
    </div>
  </div>
</template>

<script>
import ButtonExternalLink from '@/components/ui/button/button-external-link'
import ButtonLink from '@/components/ui/button/button-link'

export default {
  components: {
    ButtonExternalLink,
    ButtonLink,
  },
}
</script>

<style lang="scss">
@import '@/css/import/vars.scss';

.newsletter {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: $screen-width-1023) {
    justify-content: space-between;
    align-items: flex-end;
  }

  &__text-wrapper {
    text-align: center;
    flex-basis: 100%;

    @media (min-width: $screen-width-1023) {
      text-align: left;
      flex-basis: auto;
    }

    h2 {
      font-variation-settings: 'wght' 400;
      color: $color-headline;
      font-size: 36px;
      margin-bottom: 1rem;

      @media (min-width: $screen-width-1023) {
        font-size: 42px;
      }
    }

    p {
      font-size: 18px;

      @media (min-width: $screen-width-1023) {
        margin-bottom: 0;
      }
    }
  }

  &__buttons {
    display: flex;

    a {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>
