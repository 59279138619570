<template>
  <input
    :value="value"
    type="text"
    class="inputfield inputfield--default"
    @input="$emit('input', $event.target.value)"
    @blur="$emit('blur')"
  />
</template>

<script>
export default {
  props: {
    value: {
      required: false,
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss">
@import '../../../../css/import/vars';

.inputfield--default {
  width: 100%;
}
</style>
