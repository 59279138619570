<template>
  <i :class="`subnavigation-login-logo subnavigation-login-logo--${text.toLowerCase()}`">
    <ApoverlagLogoNavDownloads v-if="text === 'Apoverlag-EDV-Download'" />
    <ApoverlagLogoNavElearning v-if="text === 'Apoverlag-Elearning'" />
    <ApoverlagLogoNavPkaup v-if="text === 'Apoverlag-Pka-Up'" />
    <ApoverlagLogoNavGesundheitstv v-if="text === 'Apoverlag-Gesundheits-Tv'" />
    <ApoverlagLogoNavOeaz v-if="text === 'OEAZ'" />
    <!-- TODO: "Apoverlag-Arzneischnellerkennung" can be removed in the future. Stays in only for smooth switch to "Mein-Apoverlag" -->
    <ApoverlagLogoNavArzneischnellerkennung v-if="text === 'Apoverlag-Arzneischnellerkennung'" />
    <ApoverlagLogoNavPharmaschriftenreihe v-if="text === 'Apoverlag-Pharm-Schriftenreihe-Online'" />
    <ApoverlagLogoNavWarenverzeichnis v-if="text === 'Apoverlag-Warenverzeichnis-Offline'" />
    <ApoverlagLogoNavBereitschaftsdienstanzeige v-if="text === 'Apoverlag-Bereitschaftsdienstanzeige'" />
    <ApoverlagLogoNavBuchaktuell v-if="text === 'Buchaktuell'" />
    <ApoverlagLogoNavMeinApoverlag v-if="text === 'Mein-Apoverlag'" />
  </i>
</template>

<script>
import ApoverlagLogoNavDownloads from '@/components/ui/icons/apoverlag-logo-nav-downloads.svg'
import ApoverlagLogoNavElearning from '@/components/ui/icons/apoverlag-logo-nav-elearning.svg'
import ApoverlagLogoNavPkaup from '@/components/ui/icons/apoverlag-logo-nav-pkaup.svg'
import ApoverlagLogoNavGesundheitstv from '@/components/ui/icons/apoverlag-logo-nav-gesundheitstv.svg'
import ApoverlagLogoNavOeaz from '@/components/ui/icons/apoverlag-logo-nav-oeaz.svg'
import ApoverlagLogoNavArzneischnellerkennung from '@/components/ui/icons/apoverlag-logo-nav-arzneischnellerkennung.svg'
import ApoverlagLogoNavPharmaschriftenreihe from '@/components/ui/icons/apoverlag-logo-nav-pharmaschriftenreihe.svg'
import ApoverlagLogoNavWarenverzeichnis from '@/components/ui/icons/apoverlag-logo-nav-warenverzeichnis.svg'
import ApoverlagLogoNavBereitschaftsdienstanzeige from '@/components/ui/icons/apoverlag-logo-nav-bereitschaftsdienstanzeige.svg'
import ApoverlagLogoNavBuchaktuell from '@/components/ui/icons/apoverlag-logo-nav-buchaktuell.svg'
import ApoverlagLogoNavMeinApoverlag from '@/components/ui/icons/apoverlag-logo-nav-mein-apoverlag.svg'

export default {
  components: {
    ApoverlagLogoNavDownloads,
    ApoverlagLogoNavElearning,
    ApoverlagLogoNavPkaup,
    ApoverlagLogoNavGesundheitstv,
    ApoverlagLogoNavOeaz,
    ApoverlagLogoNavArzneischnellerkennung,
    ApoverlagLogoNavPharmaschriftenreihe,
    ApoverlagLogoNavWarenverzeichnis,
    ApoverlagLogoNavBereitschaftsdienstanzeige,
    ApoverlagLogoNavBuchaktuell,
    ApoverlagLogoNavMeinApoverlag,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';
.subnavigation-login-logo {
  display: block;

  svg {
    display: block;
    width: 100%;
    max-height: 40px;
  }

  &--apoverlag-elearning {
    svg {
      width: 105px;
    }
  }

  &--apoverlag-warenverzeichnis-offline {
    svg {
      width: 120px;
    }
  }

  &--apoverlag-pharm-schriftenreihe-online {
    svg {
      width: 138px;
    }
  }

  &--apoverlag-pka-up {
    svg {
      width: 105px;
    }
  }

  &--apoverlag-gesundheits-tv {
    svg {
      width: 105px;
    }
  }

  &--apoverlag-bereitschaftsdienstanzeige {
    svg {
      width: 105px;
    }
  }

  &--buchaktuell {
    svg {
      width: 105px;
    }
  }

  &--oeaz {
    svg {
      width: 105px;
    }
  }

  &--apoverlag-edv-download {
    svg {
      width: 105px;
    }
  }

  &--apoverlag-arzneischnellerkennung {
    svg {
      width: 150px;
    }
  }
}
</style>
