<template>
  <nav
    v-click-outside="hideMobileNav"
    class="header-navigation no-print"
    :aria-expanded="navigation.isActive ? 'true' : 'false'"
  >
    <button aria-label="menu" @click="toggleMobileNav">
      <IconMobileNav />
    </button>
    <div class="header-navigation-menu">
      <ul
        role="menu"
        class="header-navigation-menu__list"
        :class="{
          active: navigation.mobileProductActive || navigation.mobileLoginActive,
          'products-active': navigation.mobileProductActive,
          'login-active': navigation.mobileLoginActive,
        }"
      >
        <li class="header-navigation-menu__listitem mobile-search search-input" @click="gotoSearch">
          <DefaultInput type="search" placeholder="Suche" />
        </li>
        <NavigationItem
          v-for="(navItem, index) in headerNavigation"
          :key="index"
          :css-class-li="`${navItem.attributes.link.attributes.cssClasses.join(' ')}`"
          css-class="header-navigation-menu__link"
          :navitem="navItem"
          class="header-navigation-menu__listitem"
          @click="hideMobileNav"
        >
          <template
            v-if="navItem.attributes.children.length > 0 && navItem.attributes.link.attributes.text === 'Produkte'"
          >
            <span class="header-navigation-menu__subnav-indicator" />
            <span class="header-navigation-menu__subnav-indicator-mask" />
            <SubNavigationProducts
              class="header-navigation-menu__subnav header-navigation-menu__subnav--products"
              :children="navItem.attributes.children"
              :class="{ 'header-navigation-menu__subnav--products--top': navigation.mobileProductTop }"
              @product-inactive="setProductInactive"
            />
            <button
              class="header-navigation-menu__subnav-mobiletrigger"
              title="Produkte Untermenüpunkte"
              @click="toggleMobileProductsSubnav"
            >
              <IconNavArrow width="8" height="17" fill="#E2001A" />
            </button>
          </template>
          <template
            v-if="navItem.attributes.children.length > 0 && navItem.attributes.link.attributes.text === 'Login'"
          >
            <span class="header-navigation-menu__subnav-indicator" />
            <span class="header-navigation-menu__subnav-indicator-mask" />
            <SubNavigationLogin
              class="header-navigation-menu__subnav header-navigation-menu__subnav--login"
              :children="navItem.attributes.children"
              :class="{ 'header-navigation-menu__subnav--login--top': navigation.mobileLoginTop }"
              @login-inactive="setLoginInactive"
            />
            <button
              class="header-navigation-menu__subnav-mobileLogintrigger"
              title="Login Untermenüpunkte"
              @click="toggleMobileLoginSubnav"
            >
              Login
            </button>
          </template>
        </NavigationItem>
        <li class="header-navigation-menu__listitem" aria-label="Suche">
          <NuxtLink :to="{ name: 'suche' }" class="search-link" @click.native="focusSearch">
            <i>
              <IconMagnifier />
            </i>
          </NuxtLink>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import NavigationItem from '@/components/ui/navigation/navigation-item'
import SubNavigationProducts from '@/components/header/default/subnavigation-products'
import SubNavigationLogin from '@/components/header/default/subnavigation-login'
import IconMobileNav from '@/components/ui/icons/icon-mobile-nav.svg'
import IconNavArrow from '@/components/ui/icons/icon-nav-arrow.svg'
import IconMagnifier from '@/components/ui/icons/icon-magnifier.svg'
import DefaultInput from '@/components/ui/form/input/default'

export default {
  components: {
    IconMobileNav,
    NavigationItem,
    SubNavigationProducts,
    SubNavigationLogin,
    IconNavArrow,
    IconMagnifier,
    DefaultInput,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      navigation: {
        isActive: false,
        mobileProductActive: false,
        mobileProductTop: false,
        mobileLoginActive: false,
        mobileLoginTop: false,
      },
    }
  },
  computed: {
    headerNavigation() {
      return this.$store.getters['navigation/headerNavigation']
    },
  },
  mounted() {
    window.addEventListener('resize', this.hideMobileNav)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.hideMobileNav)
  },
  methods: {
    toggleMobileNav() {
      this.navigation.isActive = !this.navigation.isActive
    },
    hideMobileNav() {
      if (screen.width > 1023) {
        this.navigation.isActive = false
        this.navigation.mobileProductActive = false
      }
    },
    toggleMobileProductsSubnav() {
      this.navigation.mobileProductActive = !this.navigation.mobileProductActive
      this.navigation.mobileProductTop = true
    },
    toggleMobileLoginSubnav() {
      this.navigation.mobileLoginActive = !this.navigation.mobileLoginActive
      this.navigation.mobileLoginTop = true
    },
    gotoSearch() {
      this.$router.push({ name: 'suche' })
      this.navigation.isActive = false
      this.focusSearch()
    },
    focusSearch() {
      const searchInput = document.querySelector('#page-search-input')
      searchInput && searchInput.focus()
    },
    setProductInactive() {
      this.navigation.mobileProductActive = !this.navigation.mobileProductActive
      const self = this
      setTimeout(function () {
        self.navigation.mobileProductTop = false
      }, 300)
    },
    setLoginInactive() {
      this.navigation.mobileLoginActive = !this.navigation.mobileLoginActive
      const self = this
      setTimeout(function () {
        self.navigation.mobileLoginTop = false
      }, 300)
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

nav.header-navigation {
  @media screen and (min-width: $screen-width-1023) {
    display: flex;
    align-items: flex-end;
    width: calc(100% - 340px);
    justify-content: flex-end;
  }

  button[aria-label='menu'] {
    padding: 1rem;
    display: flex;
    border: none;
    outline: none;
    background: transparent;
    height: auto;

    @media screen and (min-width: $screen-width-1023) {
      display: none;
    }
  }

  .header-navigation-menu {
    display: none;
    position: absolute;
    top: 80px;
    z-index: 1;
    right: -100%;
    transition-duration: 0.3s;
    height: calc(100vh - 67px);
    width: calc(100% - 10px);
    overflow: hidden;

    @media screen and (min-width: $screen-width-1023) {
      position: relative;
      top: auto;
      right: auto;
      padding: 0;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: auto;
      overflow: visible;
    }

    &__subnav {
      display: flex;
      flex-direction: column;
      background: $color-white;

      @media screen and (min-width: $screen-width-1023) {
        display: none;
        background: none;
      }
    }

    &__list {
      margin: 0;
      padding: 1rem 1rem 2rem 1rem;
      list-style-type: none;
      transition-duration: 0.3s;
      position: relative;

      @media all and (min-width: $screen-width-1023) {
        padding: 1rem 0;
      }

      &.active {
        transform: translate3d(-100%, 0, 0);
        -ms-transform: translateX(-100%);

        @media all and (min-width: $screen-width-1023) {
          transform: translate3d(0, 0, 0);
          -ms-transform: translateX(0);
        }
      }

      .header-navigation-menu__subnav--products--top {
        z-index: 5;

        @media all and (min-width: $screen-width-1023) {
          z-index: 3;
        }
      }

      .header-navigation-menu__subnav--login--top {
        z-index: 5;

        @media all and (min-width: $screen-width-1023) {
          z-index: 3;
        }
      }

      &.active.products-active {
        .header-navigation-menu__subnav--products {
          display: flex;
          z-index: 5;

          @media all and (min-width: $screen-width-1023) {
            z-index: 3;
          }
        }

        .header-navigation-menu__subnav--login {
          display: none;

          @media all and (min-width: $screen-width-1023) {
            display: flex;
          }
        }
      }

      &.active.login-active {
        .header-navigation-menu__subnav--products {
          display: none;

          @media all and (min-width: $screen-width-1023) {
            display: flex;
          }
        }

        .header-navigation-menu__subnav--login {
          display: flex;
          z-index: 5;
        }
      }

      &item {
        padding: 0;
        line-height: 1;
        margin-bottom: 0;

        &:before {
          display: none;
        }

        &.header-login-button {
          order: 100;
          margin-top: 1rem;
          border-radius: 4px;
          color: $color-white;
          background-color: $color-red;
          width: 100%;
          border: none;
          font-size: 18px;
          height: 44px;
          padding: 0 1.5rem;
          text-align: center;
          cursor: pointer;
          position: static;

          @media all and (min-width: $screen-width-1023) {
            padding: 0 1rem;
            margin: 0;
            height: 32px;
            max-width: 80px;
            position: relative;
          }

          &:hover {
            transition-duration: 0.3s;
            background-color: darken($color-red, 5%);
          }

          .text {
            display: none;

            @media all and (min-width: $screen-width-1023) {
              display: block;
            }
          }
        }
      }
    }

    &__subnav-mobiletrigger {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 45px;
      background: none !important;
      right: 0;
      width: 25%;

      svg {
        display: block;
      }

      @media all and (min-width: $screen-width-1023) {
        display: none;
      }
    }

    &__subnav-mobileLogintrigger {
      height: 45px;
      background: none !important;
      position: absolute;
      right: 0;
      left: 0;
      width: 100%;

      svg {
        display: block;
      }

      @media all and (min-width: $screen-width-1023) {
        display: none;
      }

      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        margin-top: -23px;
      }
    }
  }

  &[aria-expanded='true'] .header-navigation-menu {
    display: flex;
    flex-direction: column;
    background-color: $color-white;
    right: 0;
    box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.4);

    @media all and (min-width: $screen-width-1023) {
      flex-direction: row;
      right: auto;
      box-shadow: none;
    }
  }

  @keyframes highlight {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &.active:before {
    animation-name: highlight;
    animation-duration: 0.3s;
    content: ' ';
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);

    @media screen and (min-width: $screen-width-1023) {
      display: none;
    }
  }

  & .header-navigation-menu__list,
  & .header-navigation-menu__list.active {
    @media screen and (min-width: $screen-width-1023) {
      display: flex;
      align-items: center;
      position: relative;
      flex-direction: row;
      background-color: transparent;
      top: auto;
      left: auto;
      right: auto;
    }
  }

  a.header-navigation-menu__link {
    font-family: $ff-headlines;
    font-weight: 700;
    color: $color-text;
    text-decoration: none;
    padding: 1.2rem 0;
    position: relative;
    font-size: 18px;
    width: 100%;
    white-space: nowrap;

    @media screen and (min-width: $screen-width-1023) {
      padding: 0 1rem;
    }

    @media screen and (min-width: 1023px) and (max-width: 1040px) {
      padding: 0 0.8rem;
    }
  }

  .header-navigation-menu__listitem {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-light-gray-border;
    position: static;

    @media screen and (min-width: $screen-width-1023) {
      border: none;
      position: relative;
    }

    &:hover a {
      transition-duration: 0.3s;
    }

    &[role='menuitem'] {
      &:hover .header-navigation-menu__link,
      & .nuxt-link-exact-active {
        &:after {
          @media all and (min-width: $screen-width-1023) {
            content: ' ';
            position: absolute;
            bottom: -10px;
            left: 1rem;
            right: 1rem;
            height: 3px;
            background-color: $color-red;
            display: block;
          }
        }
      }
    }

    &:hover {
      .header-navigation-menu {
        &__subnav {
          @media all and (min-width: $screen-width-1023) {
            display: flex;
          }

          &-indicator {
            @media all and (min-width: $screen-width-1023) {
              width: 25px;
              height: 25px;
              background-color: $color-white;
              position: absolute;
              top: 50px;
              left: 0;
              right: 0;
              margin: auto;
              transform: rotate(45deg);
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
              z-index: 2;
            }
          }

          &-indicator-mask {
            @media all and (min-width: $screen-width-1023) {
              position: absolute;
              top: 46px;
              left: 0;
              right: 0;
              margin: auto;
              z-index: 4;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 16px 16px 16px;
              border-color: transparent transparent $color-white transparent;
            }
          }
        }
      }
    }
  }

  .search-link {
    display: none;

    @media all and (min-width: $screen-width-1023) {
      display: flex;
      padding: 1rem;
      margin-right: 1rem;
    }

    &:hover {
      svg {
        path {
          fill: $color-red;
        }
      }
    }
  }

  .mobile-search {
    display: block;
    border-bottom: none;

    @media all and (min-width: $screen-width-1023) {
      display: none;
    }
  }

  .search-input {
    position: relative;
  }
}
</style>
