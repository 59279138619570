<template>
  <div class="subnavigation-login">
    <div class="subnavigation-login__mobilecontrol">
      <button class="subnavigation-login__mobilecontrol-back" @click="goBack">
        <IconNavArrow width="8" height="17" fill="#E2001A" />
        <span>zurück</span>
      </button>
      <span class="subnavigation-login__mobilecontrol-title">Login</span>
    </div>
    <ul role="menu" class="subnavigation-login__list">
      <li
        v-for="(child, index) in children"
        :key="index"
        role="menuitem"
        class="subnavigation-login__listitem"
        :class="`subnavigation-login__listitem--${child.attributes.link.attributes.text.toLowerCase()}`"
      >
        <ArticleLink
          v-if="child.attributes.link.type === 'ContentLink' && child.attributes.link.relationships.article"
          class="subnavigation-login__link"
          :article="child.attributes.link.relationships.article"
        >
          <SubnavigationLoginLogo :text="child.attributes.link.attributes.text" />
        </ArticleLink>
        <NavigationLink
          v-else-if="child.attributes.link.type === 'ChannelLink'"
          class="subnavigation-login__link"
          :channel="child.attributes.link.relationships.channel"
        >
          <SubnavigationLoginLogo :text="child.attributes.link.attributes.text" />
        </NavigationLink>
        <a
          v-else-if="child.attributes.link.attributes.url"
          class="subnavigation-login__link"
          :href="child.attributes.link.attributes.url"
          :target="child.attributes.link.attributes.target"
        >
          <SubnavigationLoginLogo :text="child.attributes.link.attributes.text" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import NavigationLink from '@/components/ui/page/navigation-link'
import ArticleLink from '@/components/article/link'

import IconNavArrow from '@/components/ui/icons/icon-nav-arrow.svg'
import SubnavigationLoginLogo from '@/components/header/default/subnavigation-login-logo'

export default {
  components: {
    NavigationLink,
    ArticleLink,
    IconNavArrow,
    SubnavigationLoginLogo,
  },
  props: {
    children: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goBack() {
      this.$emit('login-inactive')
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.subnavigation-login {
  position: absolute;
  top: 0;
  transform: translate3d(calc(100% - 2.5rem), 0, 0);
  -ms-transform: translateX(100%) translateX(-2.5rem); /* IE 11 */
  transition-duration: 0.3s;
  width: 100%;

  @media all and (min-width: $screen-width-1023) {
    transform: translate3d(0, 0, 0);
    position: fixed;
    top: 90px;
    margin: auto;
    z-index: 3;
    width: 100%;
    max-width: $content-max-width;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    right: 10px;
    max-width: 560px;
  }

  @media all and (min-width: $screen-width-1110) {
    right: calc((100% - 1110px) / 2);
  }

  &__list {
    background-color: $color-white;
    display: flex;
    width: 100%;
    list-style-type: none;
    flex-direction: row;
    flex-wrap: wrap;
    height: calc(100vh - 140px);
    overflow: scroll;
    padding: 0 !important;

    @media all and (min-width: $screen-width-1023) {
      height: auto;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: 2rem 0 0 0;
      overflow: hidden;
      right: 0;
    }

    &item {
      color: $color-text;
      padding: 2rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      max-width: 50%;
      border-right: 1px solid #e5e6e7;
      border-bottom: 1px solid #e5e6e7;
      margin: 0 !important;
      height: auto;

      @media all and (min-width: $screen-width-1023) {
        max-width: 33.3333%;
      }

      &:nth-child(2n) {
        border-right: none;

        @media all and (min-width: $screen-width-1023) {
          border-right: 1px solid #e5e6e7;
        }
      }

      &:nth-child(3n) {
        @media all and (min-width: $screen-width-1023) {
          border-right: none;
        }
      }

      &:before {
        display: none;
      }
    }
  }

  &__mobilecontrol {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e5e6e7;
    height: 60px;
    cursor: pointer;
    position: relative;

    &:before {
      display: none;
    }

    &-back {
      position: absolute;
      left: 1rem;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-red;
      background: none !important;
      padding: 0;
    }

    &-title {
      color: $color-text;
      font-size: 20px;
      font-weight: 600;
      font-variation-settings: 'wght' 600;
    }

    span {
      line-height: 100%;
    }

    svg {
      transform: rotate(180deg);
      margin-right: 0.5rem;
    }

    @media all and (min-width: $screen-width-1023) {
      display: none;
    }
  }

  &__icon {
    width: 100%;
    max-width: 150px;

    @media all and (min-width: $screen-width-1023) {
      width: 80%;
      height: 60px;
      margin: 0;
    }
  }

  &__link {
    &:before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:after {
      display: none !important;
    }
  }
}

.product-article.medien {
  .header-navigation-menu__subnav-mobiletrigger {
    svg {
      fill: $color-medien;
    }
  }

  .subnavigation-login__mobilecontrol-back {
    color: $color-medien;

    svg {
      fill: $color-medien;
    }
  }
}

.product-article.pharmainfo {
  .header-navigation-menu__subnav-mobiletrigger {
    svg {
      fill: $color-pharmainfo;
    }
  }

  .subnavigation-login__mobilecontrol-back {
    color: $color-pharmainfo;

    svg {
      fill: $color-pharmainfo;
    }
  }
}

.product-article.fortbildung {
  .header-navigation-menu__subnav-mobiletrigger {
    svg {
      fill: $color-fortbildung;
    }
  }

  .subnavigation-login__mobilecontrol-back {
    color: $color-fortbildung;

    svg {
      fill: $color-fortbildung;
    }
  }
}

.product-article.software {
  .header-navigation-menu__subnav-mobiletrigger {
    svg {
      fill: $color-software;
    }
  }

  .subnavigation-login__mobilecontrol-back {
    color: $color-software;

    svg {
      fill: $color-software;
    }
  }
}
</style>
