<template>
  <li role="menuitem" class="subnav-products-item" :class="`subnav-products-item--${itemType}`">
    <Component :is="`page-logo-${itemType}`" class="subnav-products-item__icon" />
    <ArticleLink
      v-if="item.attributes.link.type === 'ContentLink' && item.attributes.link.relationships.article"
      class="subnav-products-item__link"
      :article="item.attributes.link.relationships.article"
    >
      <span>{{ linkText }}</span>
    </ArticleLink>
    <NavigationLink
      v-else-if="item.attributes.link.type === 'ChannelLink'"
      class="subnav-products-item__link"
      :channel="item.attributes.link.relationships.channel"
    >
      <span>{{ linkText }}</span>
    </NavigationLink>
    <a
      v-else-if="item.attributes.link.attributes.url"
      class="subnav-products-item__link"
      :href="item.attributes.link.attributes.url"
      :target="item.attributes.link.attributes.target"
    >
      <span>{{ linkText }}</span>
    </a>
  </li>
</template>

<script>
import NavigationLink from '@/components/ui/page/navigation-link'
import ArticleLink from '@/components/article/link'

import PageLogoFortbildung from '@/components/ui/icons/apothekerverlag-logo-fortbildung.svg'
import PageLogoMedien from '@/components/ui/icons/apothekerverlag-logo-medien.svg'
import PageLogoPharmainfo from '@/components/ui/icons/apothekerverlag-logo-pharmainfo.svg'
import PageLogoSoftware from '@/components/ui/icons/apothekerverlag-logo-software.svg'
import IconNavArrow from '@/components/ui/icons/icon-nav-arrow.svg'

export default {
  components: {
    NavigationLink,
    ArticleLink,
    PageLogoMedien,
    PageLogoPharmainfo,
    PageLogoFortbildung,
    PageLogoSoftware,
    IconNavArrow,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    itemType() {
      return this.item.attributes.link.attributes.text.toLowerCase()
    },
    linkText() {
      return 'mehr Informationen ›'
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.subnav-products-item {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  border-bottom: 1px solid #e5e6e7;
  margin: 0 !important;

  @media all and (min-width: $screen-width-767) {
    margin: 0 !important;
  }

  @media all and (min-width: $screen-width-1023) {
    padding: 2rem 1rem;
    border-bottom: none;
    align-items: flex-start;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0.5rem 0.5rem 0.5rem 0 !important;
  }

  &:first-child {
    @media all and (min-width: $screen-width-767) {
      margin: 0 !important;
    }
    @media all and (min-width: $screen-width-1023) {
      margin: 0.5rem !important;
    }
  }

  &:last-child {
    border-right: none;

    @media all and (min-width: $screen-width-767) {
      margin: 0 !important;
    }

    @media all and (min-width: $screen-width-1023) {
      margin: 0.5rem 0.5rem 0.5rem 0 !important;
    }
  }

  &:before {
    display: none;
  }

  &--medien {
    background-color: $color-medien-light;
  }

  &--pharmainfo {
    background-color: $color-pharmainfo-light;
  }

  &--fortbildung {
    background-color: $color-fortbildung-light;
  }

  &--software {
    background-color: $color-software-light;
  }

  &__icon {
    width: 100%;
    max-width: 150px;

    @media all and (min-width: $screen-width-1023) {
      width: 80%;
      height: 60px;
      margin: 0;
    }
  }

  &__link {
    font-size: 12px;
    color: $color-red !important;

    @media all and (min-width: $screen-width-767) {
      font-size: 14px;
    }

    span {
      display: none;

      @media all and (min-width: $screen-width-1023) {
        display: inline;
      }
    }

    &:before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &:after {
      display: none !important;
    }
  }
}
</style>
