import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _60e22466 = () => interopDefault(import('../pages/apoverlags-newsletterarchiv/index.vue' /* webpackChunkName: "pages/apoverlags-newsletterarchiv/index" */))
const _42070ed3 = () => interopDefault(import('../pages/buch-aktuell/index.vue' /* webpackChunkName: "pages/buch-aktuell/index" */))
const _487168f2 = () => interopDefault(import('../pages/newsletter/index.vue' /* webpackChunkName: "pages/newsletter/index" */))
const _086d635c = () => interopDefault(import('../pages/registrierung/index.vue' /* webpackChunkName: "pages/registrierung/index" */))
const _1c9a6983 = () => interopDefault(import('../pages/suche.vue' /* webpackChunkName: "pages/suche" */))
const _6ae1c6be = () => interopDefault(import('../pages/produkte/fortbildung.vue' /* webpackChunkName: "pages/produkte/fortbildung" */))
const _57c56b1c = () => interopDefault(import('../pages/produkte/medien.vue' /* webpackChunkName: "pages/produkte/medien" */))
const _585c8186 = () => interopDefault(import('../pages/produkte/software.vue' /* webpackChunkName: "pages/produkte/software" */))
const _a8cd6d52 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _63d7ec66 = () => interopDefault(import('../pages/article/_id.vue' /* webpackChunkName: "pages/article/_id" */))
const _010efffe = () => interopDefault(import('../pages/author/_id.vue' /* webpackChunkName: "pages/author/_id" */))
const _b116f5fa = () => interopDefault(import('../pages/channel/_slug.vue' /* webpackChunkName: "pages/channel/_slug" */))
const _c3ca4b4e = () => interopDefault(import('../pages/product-inquiry/_id.vue' /* webpackChunkName: "pages/product-inquiry/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/apoverlags-newsletterarchiv",
    component: _60e22466,
    name: "apoverlags-newsletterarchiv"
  }, {
    path: "/buch-aktuell",
    component: _42070ed3,
    name: "buch-aktuell"
  }, {
    path: "/newsletter",
    component: _487168f2,
    name: "newsletter"
  }, {
    path: "/registrierung",
    component: _086d635c,
    name: "registrierung"
  }, {
    path: "/suche",
    component: _1c9a6983,
    name: "suche"
  }, {
    path: "/produkte/fortbildung",
    component: _6ae1c6be,
    name: "produkte-fortbildung"
  }, {
    path: "/produkte/medien",
    component: _57c56b1c,
    name: "produkte-medien"
  }, {
    path: "/produkte/software",
    component: _585c8186,
    name: "produkte-software"
  }, {
    path: "/",
    component: _a8cd6d52,
    name: "index"
  }, {
    path: "/article/:id?",
    component: _63d7ec66,
    name: "article-id"
  }, {
    path: "/author/:id?",
    component: _010efffe,
    name: "author-id"
  }, {
    path: "/channel/:slug?",
    component: _b116f5fa,
    name: "channel-slug"
  }, {
    path: "/product-inquiry/:id?",
    component: _c3ca4b4e,
    name: "product-inquiry-id"
  }, {
    path: "/bestellen/:id(\\d+)",
    component: _c3ca4b4e,
    name: "order-canonical"
  }, {
    path: "/autor/:authorName([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _010efffe,
    name: "author-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)+/:slug([a-z0-9A-F%-]+)-:id(\\d+)",
    component: _63d7ec66,
    name: "article-canonical"
  }, {
    path: "/:channelSlugs([a-z0-9A-F%-]+)*/:slug([a-z0-9A-F%-]+)",
    component: _b116f5fa,
    name: "channel-canonical"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
