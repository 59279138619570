import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import schema from './fragment-types.json'
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: schema,
})

export default ({ req, app }) => {
  return {
    httpEndpoint: app.$config.graphqlUrl,
    browserHttpEndpoint: process.env.GRAPHQL_PROXY_URL || app.$config.graphqlUrl,
    cache: new InMemoryCache({ fragmentMatcher }),
  }
}
