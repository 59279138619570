<template>
  <NuxtLink :to="route">
    <slot />
  </NuxtLink>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    channelSlugs() {
      return this.$parseChannelSlugs(this.article.relationships.channels, this.$config.portalId)
    },
    route() {
      return {
        name: 'article-canonical',
        params: {
          id: this.article.id,
          channelSlugs: this.channelSlugs,
          slug: this.$slugify(this.article.attributes.pageTitle), // FIXME: custom slugs can be set in backend, used for previewlinks
        },
      }
    },
  },
}
</script>
