<template>
  <a :href="articleLink">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      required: true,
    },
  },
  computed: {
    articleLink() {
      const channelSlug = this.article.relationships.channels.find(
        (channel) => channel.relationships.portal.id === this.$config.deineApothekePortalId
      ).attributes.slug
      const articleSlug = `${this.$slugify(this.article.attributes.pageTitle)}-${this.article.id}`

      return `${this.$config.deineApothekeBaseUrl}/${channelSlug}/${articleSlug}`
    },
  },
}
</script>
