<template>
  <footer class="page-footer">
    <div class="page-footer-content">
      <p class="page-footer-content__apoverlag">Apoverlag</p>
      <Newsletter />
      <hr />
      <div class="menu-wrapper">
        <i class="menu-wrapper__logo"> <Logo /> </i>
        <FooterMenu />
      </div>
      <p class="page-footer-content__copyright">
        {{ copyrightText }}
      </p>
    </div>
    <ToTopButton />
  </footer>
</template>

<script>
import Newsletter from '@/components/footer/default/newsletter'
import FooterMenu from '@/components/footer/default/footer-menu'
import Logo from '@/components/ui/icons/apothekerverlag-logo.svg'
import ToTopButton from '@/components/footer/default/to-top-button'

export default {
  components: {
    Newsletter,
    FooterMenu,
    Logo,
    ToTopButton,
  },
  computed: {
    copyrightText() {
      return `© ${new Date().getFullYear()} Österreichische Apotheker-Verlagsgesellschaft m.b.H.`
    },
  },
}
</script>

<style lang="scss">
@import '@/css/import/vars.scss';

.page-footer {
  background-color: $color-bg-gray;
  margin-top: 3rem;
  display: flex;
  justify-content: center;

  @media (min-width: $screen-width-1023) {
    margin-top: 5rem;
  }

  &-content {
    max-width: $content-max-width;
    width: 100%;
    padding: 2rem 0.5rem 0 0.5rem;

    @media (min-width: $screen-width-1023) {
      padding-top: 2.5rem;
    }

    @media (min-width: $screen-width-1110) {
      padding: 2.5rem 0 0 0;
    }

    &__apoverlag {
      text-align: center;
      color: $color-red;
      letter-spacing: 2px;
      font-size: 16px;
      text-transform: uppercase;
      margin: 0;

      @media (min-width: $screen-width-1023) {
        text-align: left;
        font-size: 18px;
      }
    }

    hr {
      border: 0;
      border-top: 1px solid $color-light-gray;
      margin: 2.5rem 0 2rem 0;

      @media (min-width: $screen-width-1023) {
        margin: 3rem 0 3.5rem 0;
      }
    }

    .menu-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @media (min-width: $screen-width-1023) {
        justify-content: space-between;
        align-items: flex-end;
      }

      &__logo {
        width: 100%;
        text-align: center;
        margin-bottom: 0.7rem;

        @media (min-width: $screen-width-1023) {
          width: auto;
          margin: 0;
        }
      }
    }

    &__copyright {
      color: $color-text-gray;
      font-size: 14px;
      text-align: center;
      margin: 1.7rem 3rem 3rem 3rem;

      @media (min-width: $screen-width-1023) {
        text-align: left;
        margin: 2rem 0;
      }
    }
  }
}
</style>
