<template>
  <nav class="footer-menu" aria-label="Footer Menu">
    <ul role="menu">
      <NavigationItem
        v-for="(navItem, index) in footerNavigation"
        :key="index"
        css-class-li=""
        css-class=""
        :navitem="navItem"
      />
    </ul>
  </nav>
</template>

<script>
import NavigationItem from '@/components/ui/navigation/navigation-item'

export default {
  components: {
    NavigationItem,
  },
  computed: {
    footerNavigation() {
      return this.$store.getters['navigation/footerNavigation']
    },
  },
}
</script>

<style lang="scss">
@import '@/css/import/vars.scss';

.footer-menu {
  ul {
    margin: 15px 0 0 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      padding: 0;
      margin: 0 15px 15px 15px;

      @media all and (min-width: $screen-width-1023) {
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }

        &:first-child {
          margin-left: 0;
        }
      }

      &::before {
        display: none;
      }

      a {
        color: $color-text;
      }
    }
  }
}
</style>
