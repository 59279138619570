<template>
  <div class="error-page header-margin">
    <div v-if="error.statusCode === 404" class="error">
      <h1 class="error-page__headline">404</h1>
      <p>Der Link ist nicht mehr aktuell oder die Seite nicht mehr online.</p>
    </div>
    <div v-else class="error">
      <h1 class="error-page__headline">Ein Fehler ist aufgetreten</h1>
    </div>
    <ButtonLink :link="{ path: '/' }"> zur Startseite </ButtonLink>
  </div>
</template>

<script>
import ButtonLink from '@/components/ui/button/button-link'

export default {
  components: {
    ButtonLink,
  },
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../css/import/vars';

.error-page {
  max-width: $page-max-width;
  min-width: $page-min-width;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 1rem;
  width: 100%;

  .link-button {
    border-radius: 3px;
    width: auto;

    @media all and (min-width: $screen-width-767) {
    }

    &:hover {
      transition-duration: 0.3s;
    }

    svg {
      margin-left: 0.5rem;
    }
  }

  &__headline {
    font-size: 4rem;
    line-height: 4rem;
    color: $color-text;
  }

  &-headline,
  p {
    margin-bottom: 0.5rem;
  }

  .error {
    margin-bottom: 2rem;
    text-align: center;
  }
}
</style>
