<template>
  <div :is="`link-${mainArticlePortal}`" :article="article" :class="{ 'teaser-link': teaserlink }">
    <slot>
      {{ article.attributes.title }}
    </slot>
  </div>
</template>

<script>
import LinkApoverlag from '@/components/article/links/link-apoverlag'
import LinkApotheke from '@/components/article/links/link-apotheke'

export default {
  components: {
    LinkApoverlag,
    LinkApotheke,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    teaserlink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    // prefers apoverlag over deine apotheke if both are present
    mainArticlePortal() {
      const articlePortals = this.article.relationships.channels.map((channel) => channel.relationships.portal.id)

      if (articlePortals.includes(this.$config.portalId)) {
        return 'apoverlag'
      }
      if (articlePortals.includes(this.$config.deineApothekePortalId)) {
        return 'apotheke'
      }
      return null
    },
  },
}
</script>

<style lang="scss">
.teaser-link {
  &:after {
    content: '/a';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
  }
}
</style>
