export const actions = {
  async nuxtServerInit({ dispatch }, { req }) {
    await dispatch('navigation/fetch')
  },
  nuxtClientInit({ dispatch }, context) {
    const routesExemptFromConsent = [
      { name: 'article-canonical', params: { id: context.$config.articleIdImpressum } },
      { name: 'article-canonical', params: { id: context.$config.articleIdDatenschutz } },
    ]

    const initConsentScripts = () => {
      const { route } = context

      if (
        routesExemptFromConsent.some(
          (exemptRoute) =>
            exemptRoute.name === route.name && parseInt(exemptRoute.params.id) === parseInt(route.params?.id)
        )
      ) {
        // if route is exempt try again after route change
        const unsubscribe = this.$router.afterEach(() => {
          initConsentScripts()
          unsubscribe()
        })

        return
      }

      dispatch('consent/init', context)
      dispatch('gtm/init', context)
    }

    initConsentScripts()
  },
}
