export const loadScript = (attributes = { src: '', async: true }) =>
  new Promise(function (resolve, reject) {
    const scriptElement = document.createElement('script')
    scriptElement.onload = resolve
    scriptElement.onerror = reject

    const { dataset } = attributes
    delete attributes.dataset

    for (const key in attributes) {
      scriptElement[key] = attributes[key]
    }

    for (const key in dataset) {
      scriptElement.dataset[key] = dataset[key]
    }

    document.head.appendChild(scriptElement)
  })
