<template>
  <li :class="cssClassLi" role="menuitem">
    <ArticleLink
      v-if="navitem.attributes.link.type === 'ContentLink' && navitem.attributes.link.relationships.article"
      :class="cssClass"
      :article="navitem.attributes.link.relationships.article"
    >
      <span class="text">
        {{ navitem.attributes.link.attributes.text }}
      </span>
    </ArticleLink>
    <NavigationLink
      v-else-if="navitem.attributes.link.type === 'ChannelLink'"
      :class="cssClass"
      :channel="navitem.attributes.link.relationships.channel"
    >
      <span class="text">
        {{ navitem.attributes.link.attributes.text }}
      </span>
    </NavigationLink>
    <a
      v-else-if="navitem.attributes.link.attributes.url"
      :class="cssClass"
      :href="navitem.attributes.link.attributes.url"
      :target="navitem.attributes.link.attributes.target"
    >
      <span class="text">
        {{ navitem.attributes.link.attributes.text }}
      </span>
    </a>
    <template v-else>
      <span class="text">
        {{ navitem.attributes.link.attributes.text }}
      </span>
    </template>
    <slot />
  </li>
</template>

<script>
import NavigationLink from '@/components/ui/page/navigation-link'
import ArticleLink from '@/components/article/link'

export default {
  components: {
    NavigationLink,
    ArticleLink,
  },
  props: {
    cssClassLi: {
      type: String,
      required: true,
    },
    cssClass: {
      type: String,
      required: true,
    },
    navitem: {
      type: Object,
      required: true,
    },
  },
}
</script>
