<template>
  <header class="page-header noprint" :class="{ 'page-header--scrolled': isScrolled }" role="banner">
    <div class="page-header-content">
      <NuxtLink :class="`page-header-content__logolink page-header-content__logolink--${logo}`" :to="{ name: 'index' }">
        <Component :is="`page-logo-${logo}`" />
      </NuxtLink>
      <div v-if="logo === 'buchaktuell'" class="current-books-logo">
        <a href="https://www.buchkatalog-reloaded.de/59227">
          <PageLogoBuchaktuell />
        </a>
      </div>
      <HeaderNavigation />
    </div>
  </header>
</template>

<script>
import PageLogoDefault from '@/components/ui/icons/apothekerverlag-logo-default.svg'
import PageLogoFortbildung from '@/components/ui/icons/apothekerverlag-logo-fortbildung.svg'
import PageLogoMedien from '@/components/ui/icons/apothekerverlag-logo-medien.svg'
import PageLogoPharmainfo from '@/components/ui/icons/apothekerverlag-logo-pharmainfo.svg'
import PageLogoSoftware from '@/components/ui/icons/apothekerverlag-logo-software.svg'

import PageLogoElearning from '@/components/ui/icons/apothekerverlag-logo-elearning.svg'
import PageLogoArzneispezialitaeten from '@/components/ui/icons/apothekerverlag-logo-arzneispezialitaeten.svg'
import PageLogoBereitschaftsdienstanzeige from '@/components/ui/icons/apothekerverlag-logo-bereitschaftsdienstanzeige.svg'
import PageLogoGesundheitstv from '@/components/ui/icons/apothekerverlag-logo-gesundheitstv.svg'
import PageLogoBuchaktuell from '@/components/ui/icons/apothekerverlag-logo-buchaktuell.svg'

import HeaderNavigation from '@/components/header/default/navigation'

export default {
  components: {
    PageLogoDefault,
    PageLogoFortbildung,
    PageLogoMedien,
    PageLogoPharmainfo,
    PageLogoSoftware,
    PageLogoElearning,
    PageLogoArzneispezialitaeten,
    PageLogoBereitschaftsdienstanzeige,
    PageLogoGesundheitstv,
    PageLogoBuchaktuell,
    HeaderNavigation,
  },
  props: {
    logo: {
      type: String,
      required: false,
      default: 'default',
    },
  },
  data() {
    return {
      isScrolled: false,
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 150
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars.scss';

.page-header {
  max-width: $page-max-width;
  width: 100%;
  position: fixed;
  z-index: 4;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-white;
  transition-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.88, 0.01, 0, 0.99);

  &-content {
    width: 100%;
    max-width: $content-max-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-light-gray;
    height: 80px;
    transition-duration: 0.2s;
    padding: 0 0.5rem;
    position: relative;

    @media all and (min-width: $screen-width-1023) {
      height: 152px;
    }

    @media all and (min-width: $screen-width-1110) {
      padding: 0;
    }

    &__logolink {
      transition-duration: 0.2s;

      svg {
        height: 40px;
        display: block;

        @media all and (min-width: $screen-width-1023) {
          height: 58px;
        }
      }
    }

    .current-books-logo {
      max-width: 300px;
      margin-left: -50px;
      @media all and (min-width: $screen-width-1023) {
        max-width: 300px;
        margin-left: -40px;
      }
    }
  }

  &--scrolled {
    transition-duration: 0.2s;

    .page-header-content {
      transition-duration: 0.2s;
    }
  }
}
</style>
