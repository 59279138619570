import { init } from '@/utils/consentmanager'

export const VENDOR_ID_GTM = 's905'

export const state = () => ({
  vendorConsent: {
    [VENDOR_ID_GTM]: false,
  },
})

export const mutations = {
  SET_VENDOR_CONSENT: (state, { vendor, value }) => (state.vendorConsent[vendor] = value),
}

export const actions = {
  init({ dispatch }) {
    if (this.initConsentPromise) {
      return this.initConsentPromise
    }

    try {
      this.initConsentPromise = new Promise((resolve) => {
        init()
        window.__cmp(
          'addEventListener',
          [
            'consent',
            async () => {
              await dispatch('loadConsent')
              resolve()
            },
            false,
          ],
          null
        )
      })

      return this.initConsentPromise
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      this.initConsentPromise = Promise.resolve(false)
      return this.initConsentPromise
    }
  },
  loadConsent({ commit }) {
    const { vendorConsents } = window.__cmp('getCMPData')

    const vendors = [VENDOR_ID_GTM]
    vendors.forEach((vendor) => {
      commit('SET_VENDOR_CONSENT', { vendor, value: !!vendorConsents[vendor] })
    })
  },
  giveVendorConsent({ commit, dispatch, state }, vendor) {
    window.__cmp('setVendorConsent', [vendor, 1])
  },
  /**
   * This asynchronous action returns the consent value only after the consent
   * tool is loaded and the user has interacted with it. This is important for
   * properly binding non-reactive actions to the consent (especially those
   * firing early, f.e. tracking scripts).
   *
   */
  async hasVendorConsent({ dispatch, state }, vendor) {
    try {
      await dispatch('init')
      return state.vendorConsent[vendor]
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(vendor, e)
      return false
    }
  },
}

export const getters = {
  /*
    This getter returns the current consent value from the state, which defaults
    to false if consent tool is not yet loaded. This is OK for conditionally rendering template
    parts which require consent (f.e. media embeds) - since they will react to the change automatically.
  */
  hasVendorConsent: (state) => (vendor) => state.vendorConsent[vendor],
}
