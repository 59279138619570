import { VENDOR_ID_GTM } from './consent'
import { loadScript } from '@/utils/scripts'

let loadGtmPromise = null

const loadGtm = (googleTagManagerId) => {
  if (!googleTagManagerId) {
    // eslint-disable-next-line no-console
    console.warn('Google Tag Manager is not available in this environment')
    return
  }

  if (loadGtmPromise) {
    return loadGtmPromise
  }

  window.gtag('js', new Date())
  window.gtag('config', googleTagManagerId)

  loadGtmPromise = loadScript({
    src: `https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}`,
    async: true,
  })

  return loadGtmPromise
}

export const actions = {
  async init({ dispatch }) {
    const hasConsent = await dispatch('consent/hasVendorConsent', VENDOR_ID_GTM, { root: true })
    if (!hasConsent) {
      return
    }

    window.dataLayer = window.dataLayer || []
    window.gtag = function () {
      window.dataLayer.push(arguments)
    }

    return loadGtm(this.$config.gtmId)
  },
}
