<template>
  <a class="button-link" target="_blank" :href="link">
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars.scss';

.button-link {
  border-radius: 4px;
  color: $color-white;
  background-color: $color-red;
  border: none;
  font-size: 18px;
  padding: 0.4rem 0.7rem;

  &:hover {
    transition-duration: 0.3s;
    background-color: darken($color-red, 5%);
  }

  &:focus {
    color: $color-white;
    transition-duration: unset;
  }
}
</style>
