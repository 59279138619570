<template>
  <NuxtLink :to="route">
    <slot>
      {{ channel.attributes.name }}
    </slot>
  </NuxtLink>
</template>

<script>
export default {
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    channelSlugs() {
      return this.$parseChannelSlugs([this.channel], this.$config.portalId).slice(0, -1)
    },
    route() {
      return {
        name: 'channel-canonical',
        params: {
          id: this.channel.id,
          channelSlugs: this.channelSlugs,
          slug: this.$slugify(this.channel.attributes.slug, { lower: true }),
        },
      }
    },
  },
}
</script>
