<template>
  <ul class="sticky-notes">
    <li>
      <button class="sticky-button" @click.prevent="toggleContact">
        <IconMail class="_icon" />
        <span class="_label">Kontakt</span>
      </button>
      <Transition name="sticky-fade">
        <div v-show="isContactShown" class="sticky-card">
          <h3 class="sticky-header">
            <IconMail class="_icon" />
            Verkauf
            <button class="_close-button" @click.prevent="closeContact">
              <span>Close Contact Info</span>
              <IconCross class="_icon _cross" />
            </button>
          </h3>
          <ul class="sticky-info-list">
            <li>
              <a class="_link" href="tel:+43 1 4023588-535">
                <IconPhone class="_icon _phone" />
                +43 1 4023588-535
              </a>
            </li>
            <li>
              <a class="_link" href="mailto:verkauf@apoverlag.at">
                <IconMail class="_icon" />
                verkauf@apoverlag.at
              </a>
            </li>
          </ul>
        </div>
      </Transition>
    </li>
    <li>
      <button class="sticky-button" @click.prevent="toggleSupport">
        <IconSupport class="_icon" />
        <span class="_label">Support</span>
      </button>
      <Transition name="sticky-fade">
        <div v-show="isSupportShown" class="sticky-card">
          <h3 class="sticky-header">
            <IconSupport class="_icon _support" />
            IT Support
            <button class="_close-button" @click.prevent="closeSupport">
              <span>Close Support Info</span>
              <IconCross class="_icon _cross" />
            </button>
          </h3>
          <ul class="sticky-info-list">
            <li>
              <a class="_link" href="tel:+43 1 4023588-911">
                <IconPhone class="_icon _phone" />
                +43 1 4023588-911
              </a>
            </li>
            <li>
              <a class="_link" href="mailto:support@apoverlag.at">
                <IconMail class="_icon _mail" />
                support@apoverlag.at
              </a>
            </li>
          </ul>
        </div>
      </Transition>
    </li>
  </ul>
</template>

<script>
import IconSupport from '@/components/ui/icons/icon-support.svg'
import IconMail from '@/components/ui/icons/icon-mail.svg'
import IconPhone from '@/components/ui/icons/icon-phone.svg'
import IconCross from '@/components/ui/icons/icon-cross.svg'

export default {
  components: {
    IconSupport,
    IconMail,
    IconPhone,
    IconCross,
  },
  data() {
    return {
      isSupportShown: false,
      isContactShown: false,
    }
  },
  methods: {
    toggleSupport() {
      this.isSupportShown = !this.isSupportShown
      if (this.isSupportShown) {
        this.isContactShown = false
      }
    },
    toggleContact() {
      this.isContactShown = !this.isContactShown
      if (this.isContactShown) {
        this.isSupportShown = false
      }
    },
    closeSupport() {
      this.isSupportShown = false
    },
    closeContact() {
      this.isContactShown = false
    },
  },
}
</script>

<style lang="scss">
@import '../../css/import/vars.scss';
@import '../../css/import/mixins.scss';

.sticky-notes {
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
  margin: 0;
  list-style: none;
  text-align: right;

  > li {
    position: static;
    display: inline-block;
    padding-left: 10px;

    &::before {
      content: none;
    }
  }
}

.sticky-button {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  border-radius: 0;

  ._icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    transform: translate3d(-50%, -50%, 0);
  }

  ._label {
    @include element-invisible;
  }

  @media all and (min-width: $screen-width-1023) {
    width: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 44px;
    font-size: 16px;
    line-height: 1;

    ._icon {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate3d(0, -50%, 0);
    }

    ._label {
      @include element-invisible-off;
    }
  }
}

.sticky-card {
  position: absolute;
  bottom: 140%;
  right: 20px;
  width: 100vw;
  max-width: 330px;
  padding: 0;
  border-radius: 4px;
  text-align: left;
  background-color: $color-white;
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.sticky-header {
  position: relative;
  width: 100%;
  padding: 12px;
  padding-left: 46px;
  background-color: $color-red;
  color: $color-white;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;

  ._icon {
    position: absolute;
    top: 12px;
    left: 10px;
    display: block;
    width: 24px;

    &._support {
      top: 10px;
      width: 22px;
    }
  }

  ._close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;

    span {
      @include element-invisible;
    }
    ._icon {
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}

.sticky-info-list {
  padding: 20px;
  padding-bottom: 24px;
  margin: 0;
  list-style: none;

  > li {
    position: relative;
    padding-left: 0;
    margin-bottom: 14px;

    &::before {
      content: none;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  ._link {
    padding-left: 40px;
    color: $color-text;
  }

  ._icon {
    position: absolute;
    top: 4px;
    left: 0px;
    display: inline-block;
    width: 22px;

    path {
      fill: $color-red;
    }

    &._phone {
      width: 20px;
    }
  }
}

.sticky-fade-leave-active,
.sticky-fade-enter-active {
  transition-property: transform, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
}

.sticky-fade-enter-to,
.sticky-fade-leave {
  transform: translate3d(0, 0px, 0);
  opacity: 1;
}

.sticky-fade-leave-to,
.sticky-fade-enter {
  transform: translate3d(0, 6px, 0);
  opacity: 0;
}
</style>
