<template>
  <div class="subnavigation-products">
    <div class="subnavigation-products__mobilecontrol">
      <button class="subnavigation-products__mobilecontrol-back" @click="goBack">
        <IconNavArrow width="8" height="17" fill="#E2001A" />
        <span>zurück</span>
      </button>
      <span class="subnavigation-products__mobilecontrol-title">Produkte</span>
    </div>
    <ul role="menu" class="subnavigation-products__list">
      <SubnavigationProductsItem v-for="(item, index) in children" :key="index" :item="item" />
    </ul>
  </div>
</template>

<script>
import IconNavArrow from '@/components/ui/icons/icon-nav-arrow.svg'
import SubnavigationProductsItem from '@/components/header/default/subnavigation-products-item'

export default {
  components: {
    SubnavigationProductsItem,
    IconNavArrow,
  },
  props: {
    children: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goBack() {
      this.$emit('product-inactive')
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';

.subnavigation-products {
  position: absolute;
  top: 0;
  left: 16px;
  transform: translate3d(calc(100% - 1rem), 0, 0);
  -ms-transform: translateX(100%) translateX(-1rem); /* IE 11 */
  transition-duration: 0.3s;
  width: 100%;

  @media all and (min-width: $screen-width-1023) {
    transform: translate3d(0, 0, 0);
    position: fixed;
    top: 90px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 3;
    width: 100%;
    max-width: $content-max-width;
  }

  &__list {
    background-color: $color-white;
    display: flex;
    width: 100%;
    list-style-type: none;
    flex-direction: column;
    height: calc(100vh - 140px);
    overflow: scroll;
    padding: 0 !important;

    @media all and (min-width: $screen-width-1023) {
      flex-direction: row;
      height: 200px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: 2rem 0 0 0;
      overflow: hidden;
    }
  }

  &__mobilecontrol {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e5e6e7;
    height: 60px;
    cursor: pointer;
    position: relative;

    &:before {
      display: none;
    }

    &-back {
      position: absolute;
      left: 1rem;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-red;
      background: none !important;
      padding: 0;
    }

    &-title {
      font-size: 20px;
      font-weight: 600;
      font-variation-settings: 'wght' 600;
    }

    span {
      line-height: 100%;
    }

    svg {
      transform: rotate(180deg);
      margin-right: 0.5rem;
    }

    @media all and (min-width: $screen-width-1023) {
      display: none;
    }
  }
}

.product-article.medien {
  .header-navigation-menu__subnav-mobiletrigger {
    svg {
      fill: $color-medien;
    }
  }

  .subnavigation-products__mobilecontrol-back {
    color: $color-medien;

    svg {
      fill: $color-medien;
    }
  }
}

.product-article.pharmainfo {
  .header-navigation-menu__subnav-mobiletrigger {
    svg {
      fill: $color-pharmainfo;
    }
  }

  .subnavigation-products__mobilecontrol-back {
    color: $color-pharmainfo;

    svg {
      fill: $color-pharmainfo;
    }
  }
}

.product-article.fortbildung {
  .header-navigation-menu__subnav-mobiletrigger {
    svg {
      fill: $color-fortbildung;
    }
  }

  .subnavigation-products__mobilecontrol-back {
    color: $color-fortbildung;

    svg {
      fill: $color-fortbildung;
    }
  }
}

.product-article.software {
  .header-navigation-menu__subnav-mobiletrigger {
    svg {
      fill: $color-software;
    }
  }

  .subnavigation-products__mobilecontrol-back {
    color: $color-software;

    svg {
      fill: $color-software;
    }
  }
}
</style>
