<template>
  <button :class="{ active: showButton }" class="to-top-button" title="To Top" @click="scrollToTop">
    <IconArrow class="to-top-button__white" fill="#fff" />
    <IconArrow class="to-top-button__gray" fill="#b2b2b3" />
  </button>
</template>

<script>
import IconArrow from '@/components/ui/icons/icon-arrow.svg'

export default {
  components: {
    IconArrow,
  },
  data() {
    return {
      showButton: false,
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 150) {
        this.showButton = true
      } else {
        this.showButton = false
      }
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/css/import/vars.scss';

.to-top-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 56px;
  height: 56px;
  transition: all 400ms ease-in-out;
  text-decoration: none;
  position: fixed;
  z-index: 100;
  bottom: 100px;
  right: 10px;
  overflow: hidden;
  width: 56px;
  height: 56px;
  border: none;
  background-color: $color-red;
  border-radius: 100%;
  opacity: 0;
  display: none;

  @media (min-width: $screen-width-1023) {
    display: flex;
  }

  &.active {
    opacity: 1;
  }

  svg {
    width: 23px;
    height: 12px;
    position: absolute;
    left: 0;
    right: 0;
    margin: -2px auto 0 auto;
    transition: all 400ms ease-in-out;
  }

  &__gray {
    top: 70px;
  }

  &__white {
    top: 23px;
  }

  &:hover {
    background-color: $color-light-gray;

    .to-top-button__gray {
      top: 23px;
    }

    .to-top-button__white {
      top: -70px;
    }
  }
}
</style>
